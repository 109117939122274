import React, {
  useState,
  useEffect
} from 'react'

export default props => {

  const [ visible, setVisible ] = useState(false)

  useEffect(() => {

    console.log(visible)

    setVisible(false)

    const timeout = setTimeout(() => {

      setVisible(true)

    }, 600)

    return () => {

      setVisible(false)
      clearTimeout(timeout)
    }
  }, [])

  return <div 
    className={`
      Fuser
      ${ visible ? 'visible' : '' }
    `}
  >
    {  props.children }
  </div>
}