import React from 'react'
import Fuser from './fuser'

export default props => {

  return <Fuser>
    <div className="Page Gamification">
      <div className="Text">
        Gamification
      </div>
      <div className="Description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Donec eros sem, lacinia at lacinia nec, rutrum vel elit. 
        Ut tincidunt euismod enim, et vehicula arcu congue eu. 
        Cras eget felis et enim euismod finibus. 
        Curabitur dictum facilisis enim eget mattis. 
        Donec nec mi id odio ullamcorper ultricies vitae non lacus. 
        Aenean in tellus enim. Proin pretium ligula iaculis eros 
        tristique rutrum. Pellentesque scelerisque sodales egestas. 
        Curabitur ultrices convallis neque sit amet faucibus. 
      </div>
    </div>
  </Fuser>
}