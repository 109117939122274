
import React from 'react'
import { 
  Routes, 
  Route 
} from 'react-router-dom'
import Layout from './layout'
import Fuser from './fuser'
import PageHome from './page-home'
import PageGamificacion from './page-gamification'
import PageReact from './page-react'
import PageWordpress from './page-wordpress'

export default props => {    

  return <> 
    { 
      <Routes>  
        <Route element={ <Layout/> } >
          <Route path="/" element={ <PageHome /> } />
          <Route path="/gamification" element={ <PageGamificacion /> } />
          <Route path="/react" element={ <PageReact /> } />
          <Route path="/wordpress" element={ <PageWordpress /> } />
        </Route>
      </Routes>
    }
  </>
}