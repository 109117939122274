import React from 'react'
import {
  useNavigate
} from 'react-router-dom'
import { Skull } from 'lucide-react';

export default props => {

  const navigate = useNavigate()

  const go = page => {

    navigate(page)
  }

  return <div id="Menu">   
    <div className="Launcher">
      <Skull />
    </div>
    <div className="Options">
      <div 
        className="Option"
        onClick={ () => go('/') }
      >
        Home
      </div>
      <div 
        className="Option"
        onClick={ () => go('/gamification') }
      >
        Gamificación
      </div>
      <div 
        className="Option"
        onClick={ () => go('/react') }
      >
        React
      </div>
      <div 
        className="Option"
        onClick={ () => go('/wordpress') }
      >
        Wordpress
      </div>
    </div>
  </div>
}
